import { useState } from "react";
import logo from "./logo.png";
import dBludata from "./desenvolvido-bludata.svg";

const App = () => {
  const [conta, setConta] = useState("");

  const handle = (event) => {
    let value = event.target.value;
    let valueReturn = "";
    value = value.replace(/\D/g, "");
    if (value.length >= 2) {
      valueReturn = `${value.slice(0, -1)}-${value.slice(-1)}`;
    }
    else {
      valueReturn = value;
    }
    setConta(valueReturn);
  }

  const KeySpect = (event) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      send(conta);
    }
  }

  const send = (number) => {
    console.log(number);
    window.location.replace(`https://www.credinet.com.br/?conta=${number}`);
  }

  return (
    <div className="main">
      <div className="form">
        <img className="logo" src={logo} alt="Logo Credinet" />
        <input
          className="conta-input"
          type="text"
          placeholder="Nº Conta"
          id="conta"
          name="conta"
          required
          maxLength="8"
          value={conta}
          onChange={handle}
          onKeyDown={KeySpect}
        />
        <button className="send-btn" onClick={() => send(conta)}>
          Entrar
        </button>
      </div>
      <span>Para acesso ao site da Creditran</span>
      <span><a href="https://www.sicoob.com.br/web/sicoobcreditran" rel="noopener noreferrer">Clique aqui</a></span>
      <img className="desenv-bludata" src={dBludata} alt="Logo Desenvolvidor por Bludata Software" />
    </div>
  );
}

export default App;
